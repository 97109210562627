<template>
	<div>
		<form-rating :model-value="ratingConfig[props.type].avg" small readonly class="flex justify-center md:justify-start"/>

		<p class="text-center md:text-left mt-2 text-sm text-gray-800 dark:text-neutral-200">
			<span class="font-bold">{{ n(ratingConfig[props.type].avg) }}</span> / 5 - {{ n(ratingConfig[props.type].min_count) }}+ {{ t('label.reviews') }}
		</p>

		<app-store-button v-if="type === 'apple'" class="flex justify-center md:justify-start mt-4" :height="40"/>
		<play-store-button v-else-if="type === 'google'" class="flex justify-center md:justify-start mt-4" :height="40"/>
	</div>
</template>

<script setup lang="ts">
const props = defineProps<{
	type: 'apple' | 'google',
}>();

const {n, t} = useI18n();

const ratingConfig = {
	apple: {
		avg: 4.6,
		min_count: 100
	},
	google: {
		avg: 4.3,
		min_count: 50
	},
}

</script>